import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

const ScrollButton = ({ chatContainerRef }) => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (!chatContainerRef.current) return;
      
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isNotAtBottom = scrollHeight - scrollTop - clientHeight > 100;
      setShowScrollButton(isNotAtBottom);
    };

    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScroll);
      // Also check when content changes
      const observer = new MutationObserver(checkScroll);
      observer.observe(container, { childList: true, subtree: true });

      // Initial check
      checkScroll();

      return () => {
        container.removeEventListener('scroll', checkScroll);
        observer.disconnect();
      };
    }
  }, [chatContainerRef]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  if (!showScrollButton) return null;

  return (
    <button
      onClick={scrollToBottom}
      className="fixed bottom-32 left-[40%] -mb-10 transform -translate-x-1/2 bg-[#B03982] hover:bg-[#8c2d66] text-white rounded-full p-2 shadow-lg transition-all duration-200 z-50 cursor-pointer"
      aria-label="Scroll to bottom"
    >
      <ChevronDown className="w-6 h-6" />
    </button>
  );
};

export default ScrollButton;